/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-concat */

import { useLocation } from "react-router";
import { ucsfInstance } from "../axios/ucsf";
import { store } from 'react-notifications-component';
import { GetObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { COMPONENT_APIS } from "../utils/omi-api";
import { covariationModuleItemList, featureMatchOptionsList, featureSearchModuleDefinitionList, geneSetsItemList, greyedAnalysesItemList, greyedDataItemList, enrichMentAnalysisModuleDefinitionList } from "../../pages/post-auth/home/advanced-search/data/advanced-search.data";
import { convertPermType, PERMISSION_EXT } from "../utils/permissions";
import AuthService from "./authentication-service";
import { UP_ACCESS } from "../utils/permissions";
import { dropdown } from "../components/query-builder/query-builder.data";
import { Subject } from 'rxjs';
import { CONST } from "../utils/omi-constants";

const advancedSearchSubject = new Subject();
const AppService = {
    interval: null,
    timerVal: 0,
    networkErrorCount: 0,
    notifArray: [],
    sendMessage: response => advancedSearchSubject.next(response),
    clearMessages: () => advancedSearchSubject.next(),
    getMessage: () => advancedSearchSubject.asObservable(),
    wsDowloadZip: (appId) => `${CONST.WS_POINT}/ws/download-as-zip/${appId}/`,
    wsDockerEndPoint: (appId) => `${CONST.WS_POINT}/ws/download-docker-module/${appId}/`,
    wsKmeEndPoint: (appId) => `${CONST.WS_POINT}/ws/download-kme-docker-module/${appId}/`,
    wsSearchEndPoint: (appId) => `${CONST.WS_POINT}/ws/advanced-search/${appId}/`,
    newSocketConnection: (endPoint) => {
        const ws = new WebSocket(endPoint);
        return ws;
    },
    wsCommentNotifEndPoint: (userId) => `${CONST.WS_POINT}/ws/mention-notification/${userId}/`,
    debounce: (callback, delay) => {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => callback(...args), delay);
        }
    },
    convertName: (keyValue = '', char = '_') => {
        if (keyValue && keyValue.includes(char)) {
            let value = '';
            const valArray = keyValue.split(char);
            valArray.forEach(item => (value += item.trim() + ' '));
            return value.trim();
        }
        return keyValue;
    },
    convertNameWithChar: (strVal = '', charToAdd = '_') => {
        if (strVal && strVal.includes(' ')) {
            let value = '';
            strVal = strVal.trim();
            const valArray = strVal.split(' ');
            value = valArray.join('_');
            return value.trim();
        }
        return strVal.trim();
    },
    randomColor: () => {
        var letters = '0123456789ABCDEF'.split('');
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.round(Math.random() * 15)];
        }
        return color;
    },
    getRandomId: (len) => {
        var text = "";
        var char_list = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
        for (var i = 0; i < len; i++) {
            text += char_list.charAt(Math.floor(Math.random() * char_list.length));
        }
        return text;
    },
    getRandomAlphaNumId: (len) => {
        var text = "";
        var char_list = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (var i = 0; i < len; i++) {
            text += char_list.charAt(Math.floor(Math.random() * char_list.length));
        }
        return text;
    },
    removeDuplicateObjectFromArray: (array = [], key) => {
        let filteredArraysWithKey = array.filter(data => data[key]);
        let filteredArraysWithNoKey = array.filter(data => !data[key]);
        let filteredArray = filteredArraysWithKey.filter((obj, index, self) => index === self.findIndex((el) => (el[key] === obj[key])));
        let combinedArray = [...filteredArray, ...filteredArraysWithNoKey];
        return combinedArray;
        // return array.filter((obj, index, self) => index === self.findIndex((el) => (el[key] === obj[key])));
    },
    objectToString: (obj) => {
        var tabjson = [];
        for (var p in obj) {
            if (obj.hasOwnProperty(p)) {
                tabjson.push('"' + p + '"' + ':' + obj[p]);
            }
        }
        return '{' + tabjson.join(',') + '}';
    },
    isObjectValid: (obj, limit = null) => {
        if (obj && Object.keys(obj).length) {
            return true;
        }
        return false;
    },
    isLoadedBasedOnLimit: (obj, limit = null) => {
        if (obj && limit && Object.keys(obj).length >= limit) {
            return false;
        }
        return true;
    },
    getAwsCredentials: (val = false) => {
        const params = val ? '?upload=1' : '';
        const awsCreds = JSON.parse(AppService.getStorageValue('awsCreds'));
        if (awsCreds && (AppService.timerVal > 0 && AppService.timerVal <= 1200000)) {
            return new Promise((resolve, reject) => {
                resolve(awsCreds);
            });
        } else {
            return new Promise((resolve, reject) => {
                ucsfInstance.get(COMPONENT_APIS.AWS_TEMP_CREDENTIALS(params), { withCredentials: false }).then(response => {
                    if (response.data) {
                        AppService.timerVal = 0;
                        clearInterval(AppService.interval);
                        AppService.setStorageValue('awsCreds', JSON.stringify(response.data));
                        AppService.interval = setInterval(() => {
                            AppService.timerVal += 1000;
                            if (AppService.timerVal >= 1200000) {
                                AppService.removeStorageVal('awsCreds');
                                AppService.timerVal = 0;
                                clearInterval(AppService.interval);
                            }
                        }, 1000);
                        resolve(response.data);
                    }
                }).catch(error => {
                    console.log(error);
                    AppService.removeStorageVal('awsCreds');
                    reject(error);
                });
            });
        }
    },
    formatBytes: (bytes = 0, decimals = 0, byteVal = false) => {
        if (bytes === 0 && !byteVal) return '0 B';
        else if (typeof bytes === 'string') return bytes;
        else if (bytes === 0) return 0;
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        if (!byteVal) {
            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
        }
        return parseFloat((bytes).toFixed(dm)) * k;
    },
    LOCATION: () => {
        const location = useLocation();
        const path = location.pathname;
        const store = window.localStorage;
        let url = '';
        let prevUrl = '';
        url = store.getItem('url');
        store.setItem('prevUrl', url);
        store.setItem('url', path);
        url = store.getItem('url');
        prevUrl = store.getItem('prevUrl');
        return { url, prevUrl };
    },
    setStorageValue: (key, val) => {
        const stringVal = typeof val !== 'string' ? JSON.stringify(val) : val;
        const keyVal = typeof key !== 'string' ? key.toString() : key;
        window.localStorage.setItem(keyVal, stringVal);
    },
    getStorageValue: (key) => {
        const val = window.localStorage.getItem(key);
        if (val) {
            if (val.trim() === 'true') { return true; }
            else if (val.trim() === 'false') { return false; }
        }
        return val;
    },
    removeStorageVal: (key) => {
        window.localStorage.removeItem(key);
    },
    getFileType: (url) => {
        url = url?.toLowerCase();
        if (url.includes('.pdf')) {
            return 'pdf';
        } else if (url.includes('.txt')) {
            return 'txt';
        } else if (url.includes('.xls')) {
            return 'xls';
        } else if (url.includes('.xlsx')) {
            return 'xlsx';
        } else if (url.includes('.csv')) {
            return 'csv';
        } else if (url.includes('.r') || url.includes('.R')) {
            return 'r-code';
        } else {
            return 'folder';
        }
    },
    fileSizeChecker: (item, modalData) => {
        let exceedsLimit = '';
        if (
            modalData && modalData.sizeDisplayName && (item.toLowerCase() === 'preview') &&
            (
                modalData.sizeDisplayName.includes('MB') ||
                modalData.sizeDisplayName.includes('GB') ||
                modalData.sizeDisplayName.includes('TB') ||
                modalData.sizeDisplayName.includes('PB') ||
                modalData.sizeDisplayName.includes('ZB')
            )
        ) {
            const valArray = modalData.sizeDisplayName.split(' ');
            const val = Number(valArray[0]);
            if (val > 1) {
                return 'grey';
            }
        }
        return exceedsLimit;
    },
    attributeVal: (attributeValue, attributeList = []) => {
        let value = '';
        value = attributeList.find(attribute => (attributeValue === attribute));
        if (value) { return value; }
        return attributeValue;
    },
    serializePossibleValues: (attributeList = []) => {
        const attributeObject = {};
        if (attributeList && attributeList.length) {
            attributeList.forEach(attribute => {
                attributeObject[attribute.name] = attribute.possible_values;
            });
        }
        return attributeObject;
    },
    fetchQueryParams: (searchParams) => {
        const urlParams = new URLSearchParams(searchParams);
        const queryParams = Object.fromEntries(urlParams);
        return queryParams;
    },
    getConvertedArrayList: (object) => {
        return Object.keys(object).map(key => ({ [key]: object[key] }));
    },
    extractURL: (url, fileType) => {
        if (url.includes('.txt') || url.includes('.pdf') || url.includes('.csv')) {
            const type = url.includes('X-Amz-Algorithm') ? 'X-Amz-Algorithm' : url.includes('.csv') ? '.csv' : fileType;
            const urlArray = url.split(type);
            const baseUrl = urlArray[0];
            const encodedURL = encodeURIComponent(urlArray[1]);
            return `${baseUrl}${type}${encodedURL}`;
        } else if (url.toLowerCase().includes('.r')) {
            const type = url.includes('.R') ? '.R' : '.r';
            const urlArray = url.split(type);
            const baseUrl = urlArray[0];
            const encodedURL = encodeURIComponent(urlArray[1]);
            return `${baseUrl}${type}${encodedURL}`;
        }
    },
    decimalValCorrection: (val = 0, decimalPlace = 3, toBeFixed = true) => {
        const numVal = Number(val);
        if (isNaN(numVal)) {
            if (typeof val === 'string' && val.trim() === '') {
                return 'NA';
            }
            return val;
        } else if (toBeFixed) {
            const numStrArray = numVal.toString().split('.');
            const numericPart = numStrArray[0];
            let decimalPart = numStrArray[1];
            let strNum = '';
            if (decimalPart && decimalPart.length >= decimalPlace) {
                decimalPart = decimalPart.substring(0, decimalPlace);
            }
            if (decimalPart && decimalPart.length < decimalPlace && decimalPlace === 4) {
                let diff = decimalPlace - decimalPart.length;
                for (let i = 0; i < diff; i++) { decimalPart += '0'; }
                strNum = `${numericPart}${decimalPart ? `.${decimalPart}` : ''}`;
                return strNum;
            } else {
                strNum = `${numericPart}${decimalPart ? `.${decimalPart}` : ''}`;
            }
            return Number(strNum);
        } else {
            return val;
        }
    },
    getBrowserInfo: () => {
        var ua = navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        if (/trident/i.test(M[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
            return { name: 'IE ', version: (tem[1] || '') };
        }
        if (M[1] === 'Chrome') {
            tem = ua.match(/\bOPR\/(\d+)/)
            if (tem != null) { return { name: 'Opera', version: tem[1] }; }
        }
        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
        if ((tem = ua.match(/version\/(\d+)/i)) != null) { M.splice(1, 1, tem[1]); }
        return {
            name: M[0],
            version: M[1]
        };
    },
    setNotification: ({
        title,
        message,
        type = 'info',
        width = 375,
        duration = 2500
    }) => {
        store.addNotification({
            title: title,
            message: message,
            type: type,
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration,
                onScreen: false,
                pauseOnHover: false
            },
            width: message ? message.length + width : width
        });
    },
    listenWS: (ws, res) => {
        return new Promise((resolve, reject) => {
            ws.onmessage = async (evt) => {
                const data = JSON.parse(evt.data);
                const s3Configuration = await {
                    credentials: {
                        accessKeyId: res['AccessKeyId'],
                        secretAccessKey: res['SecretAccessKey'],
                        sessionToken: res['SessionToken']
                    },
                    region: res['region']
                };
                const s3 = await new S3Client(s3Configuration);
                const url = await getSignedUrl(s3, new GetObjectCommand({
                    Bucket: res['bucket'],
                    Key: data['key']
                }), {
                    expiresIn: 30 * 60
                }); // window.open(url, data['key']);
                const fileNameArray = data['key'].split('/');
                const fileName = fileNameArray[1];
                resolve({ fileName, url });
            }
        });
    },
    listenWSFC: (ws) => {
        return new Promise((resolve, reject) => {
            ws.onmessage = async (evt) => {
                await AppService.getAwsCredentials().then(async res => {
                    const data = await JSON.parse(evt.data);
                    const s3Configuration = await {
                        credentials: {
                            accessKeyId: res['AccessKeyId'],
                            secretAccessKey: res['SecretAccessKey'],
                            sessionToken: res['SessionToken']
                        },
                        region: res['region']
                    };
                    const s3 = await new S3Client(s3Configuration);
                    const url = await getSignedUrl(s3, new GetObjectCommand({
                        Bucket: res['bucket'],
                        Key: data['key']
                    }), {
                        expiresIn: 30 * 60
                    }); // window.open(url, data['key']);
                    const fileNameArray = await data['key'].split('/');
                    const fileName = await fileNameArray[1];
                    await resolve({ fileName, url, key: data['key'] });
                });
            };
            ws.onerror = async (evt) => {
                await reject(evt);
            };
        });
    },
    listenWSFAS: (ws) => {
        return new Promise((resolve, reject) => {
            ws.onmessage = async (evt) => {
                let response = await JSON.parse(evt.data);
                if (response?.data?.searchData) { await delete response.data.searchData; }
                if (response?.data?.searchResults) {
                    let currentTime = new Date();
                    let { startTime } = AuthService._getStorageData(response.data.notifId, true) || {}, executionTime = 0;
                    executionTime = currentTime.getTime() - startTime;
                    response.data['resultData'] = await {
                        results: response.data.searchResults.results,
                        sample_attributes: response.data.searchResults.sample_attributes,
                        selected_attributes: response.data.selectedAtrributes,
                        isNotification: response.data?.isNotification,
                        execution_time: AppService.msToTime(executionTime) || '0'
                    };
                    if (response?.data?.searchResults && ('result_count' in response?.data?.searchResults)) {
                        response.data['resultData']['result_count'] = await response?.data?.searchResults['result_count'];
                    }
                    if (response?.data?.searchResults?.is_background_process) {
                        response.data['resultData'] = await {
                            message: response?.data?.searchResults?.message,
                            processing: response?.data?.searchResults?.is_background_process
                        };
                    }
                    await delete response.data.searchResults;
                    await delete response.data.selectedAtrributes;
                }
                let responseObj = await {
                    notifId: response?.data?.notifId, // ? response.data.notifId : AppService.getRandomAlphaNumId(7),
                    advancedSearchNotifData: response.data
                };
                if ((response?.data?.resultData?.isNotification) || (response?.data?.key)) { // counter += 1;
                    if (!AppService.notifArray.length) {
                        await AppService.sendMessage(responseObj);
                    } else if (AppService.notifArray.length) {
                        if (!AppService.notifArray.find(notif => (notif.notifId === responseObj.notifId))) {
                            AppService.notifArray = await [];
                            await AppService.sendMessage(responseObj);
                        }
                    }
                    await AppService.notifArray.push(responseObj);
                    AppService.notifArray = await AppService.removeDuplicateObjectFromArray(AppService.notifArray, 'notifId');
                } else {
                    await resolve(responseObj);
                }
            };
            ws.onerror = async (evt) => {
                await reject(evt);
            };
        });
    },
    listenWSFMC: (ws) => {
        return new Promise((resolve, reject) => {
            ws.onmessage = async (evt) => {
                let response = await JSON.parse(evt.data);
                console.log(response)
                let responseObj = {
                    notifId: response?.message?.notification_id, // ? response.data.notifId : AppService.getRandomAlphaNumId(7),
                    commentNotifData: response.message
                };
                resolve(responseObj);
            };
            ws.onerror = async (evt) => {
                reject(evt);
            };
        });
    },
    advancedSearchWS: async (evt) => {
        let response = await JSON.parse(evt.data);
        if (response?.data?.searchData) { await delete response.data.searchData; }
        if (response?.data?.searchResults && response?.data?.selectedAtrributes) {
            response.data['resultData'] = await {
                results: response.data.searchResults.results,
                sample_attributes: response.data.searchResults.sample_attributes,
                selected_attributes: response.data.selectedAtrributes,
                isNotification: response.data?.isNotification
            };
            if (response?.data?.searchResults?.is_background_process) {
                response.data['resultData'] = await {
                    message: response?.data?.searchResults?.message,
                    processing: response?.data?.searchResults?.is_background_process
                };
            }
            await delete response.data.searchResults;
            await delete response.data.selectedAtrributes;
        }
        let responseObj = await {
            notifId: response?.data?.notifId ? response.data.notifId : AppService.getRandomAlphaNumId(7),
            advancedSearchNotifData: response.data
        };
        return responseObj;
    },
    firstLetterCase: (value = '') => {
        const u = value.charAt(0).toUpperCase();
        const rem = value.substring(1);
        return `${u}${rem}`;
    },
    orderRecords: (itemList = [], orderList = [], key = null, operator = 'equals') => {
        const newList = [];
        orderList.forEach(order => {
            itemList.forEach(item => {
                if (key && operator === 'equals' && order === item[key]) {
                    newList.push(item);
                } else if (key && !operator && order[key] === item[key]) {
                    newList.push(item);
                } else if (key && operator === 'contains' && item[key].includes(order)) {
                    newList.push(item);
                } else if (!key && order === item) {
                    newList.push(item);
                }
            })
        });
        if (operator === 'equals') {
            itemList.forEach(item => {
                if (key && orderList.indexOf(item[key]) < 0) {
                    newList.push(item);
                } else if (!key && orderList.indexOf(item) < 0) {
                    newList.push(item);
                }
            });
        }
        return newList;
    },
    errorResponse: (msgObject) => {
        const msg = (values = '') => `Values of ${values} cannot be empty`;
        let val = '';
        const resData = Object.keys(msgObject).map((key, i) => {
            if (i < Object.keys(msgObject).length) {
                val += `${key}, `;
            }
            return val;
        });
        return msg(resData);
    },
    clearNullValueKeys: (payLoad) => {
        Object.keys(payLoad).map(key => {
            if (
                payLoad[key] === null ||
                payLoad[key] === '' ||
                typeof payLoad[key] === 'undefined'
            ) { delete payLoad[key]; }
            return key;
        });
    },
    sizeChecker: (sizeDisplayValue = '') => {
        const byteValArray = ['GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const sizeDataArray = sizeDisplayValue.split(' ');
        if (sizeDisplayValue) {
            if (byteValArray.indexOf(sizeDataArray[1]) >= 0) {
                return true;
            } else if (sizeDataArray[1].toLowerCase() === 'mb') {
                if (Number(sizeDataArray[0]) > 1) {
                    return true;
                }
            }
        }
        return false;
    },
    credentialObj: (res) => {
        return {
            credentials: {
                accessKeyId: res['AccessKeyId'],
                secretAccessKey: res['SecretAccessKey'],
                sessionToken: res['SessionToken']
            },
            region: res['region']
        }
    },
    getOperators: (dataArray = []) => {
        return dataArray.map(data => {
            if (dropdown.OPERATOR_DATA[data.toLowerCase()]) {
                return dropdown.OPERATOR_DATA[data.toLowerCase()];
            }
            return dropdown.OPERATOR_DATA.generateOperator(data);
        });
    },
    typeDef: (type) => {
        switch (type) {
            case 'Integer':
            case 'Numeric':
                return 'number';
            case 'Text':
                return 'string';
            case 'Date':
                return 'date';
            default:
                return 'string';
        }
    },
    attributeValueSerializer: (data) => {
        let dsObj = {
            field: '',
            key: '',
            type: '',
            possibleValues: []
        };
        if (data) {
            const dataVal = { ...data };
            const field = dataVal['Attribute'].split(' ').join('_');
            // dsObj[field] = '';
            dsObj['field'] = field;
            dsObj['key'] = dataVal['Attribute'];
            dsObj['type'] = AppService.typeDef(dataVal['Attribute Type']);
            dsObj['operators'] = dataVal['Operators'] ? AppService.getOperators(dataVal['Operators']) : dropdown.defaultOperators;
            dsObj['possibleValues'] = [];
            dsObj['isAutoComplete'] = false;
            dsObj['IsOntologyId'] = false;
            if (dataVal['Possible Values'] && dataVal['Possible Values'].length) {
                dsObj['possibleValues'] = dataVal['Possible Values'];
            }
            if (data['AutoComplete'] && data['AutoComplete'] !== '0') { dsObj['isAutoComplete'] = true; }
            if (data['IsOntologyId']) { dsObj['IsOntologyId'] = true; }
            return dsObj;
        }
        return dsObj;
    },
    formatQueryList: (arrayList = []) => {
        const listData = [];
        for (let item of arrayList) {
            let dcObj = {};
            dcObj = { ...AppService.attributeValueSerializer(item) };
            if (item['Attribute']) {
                if (item['AutoComplete'] && UP_ACCESS(['ONTOLOGY_AUTOCOMPLETE'])) {
                    listData.push(dcObj);
                } else if (!item['AutoComplete']) {
                    listData.push(dcObj);
                }
            }
        }
        return listData;
    },
    formatSampleAttributeKeyValues: (attributes) => {
        const convertedObj = {};
        attributes.forEach(attribute => {
            let entries = Object.entries(attribute)[0];
            convertedObj[entries[0]] = entries[1].map(data => ({
                name: (data.key === -99.99 || data.key === "-99.99") ? 'NA' : data.key,
                count: data.doc_count
            }));
        });
        return convertedObj;
    },
    formatEntireSampleAttributeKeyValues: (attributes) => {
        return attributes.map(data => ({
            name: (data.key === -99.99 || data.key === "-99.99") ? 'NA' : data.key,
            count: data.doc_count
        }));
    },
    dateToYMD: (edate) => {
        // var edate = '11/23/14';
        var myDate = new Date(edate);
        var d = myDate.getDate();
        var m = myDate.getMonth();
        m += 1;
        var y = myDate.getFullYear();
        if (m <= 9) { m = `0${m}`; }
        if (d <= 9) { d = `0${d}`; }
        var newdate = (y + "-" + m + "-" + d);
        return newdate;
    },
    setGreyedChecklistItems: (attributeListData, tabMenu) => {
        let defaultAndOptionalValues = attributeListData;
        if (attributeListData?.formData?.typeItems) {
            defaultAndOptionalValues = attributeListData.formData.typeItems.defaultAndOptionalValues;
        }
        const tabMenuList = [...tabMenu];
        // DATA TAB SEARCH DATA ------------------------------------------------
        const dataTab = tabMenuList.find(tab => tab.configKey === 'data');
        const dataSubTab = dataTab?.searchData;
        // ANALYSES TAB SEARCH DATA --------------------------------------------
        const analysesTab = tabMenuList.find(tab => tab.configKey === 'analyses');
        const analysesTabData = analysesTab?.searchData;
        // ---------------------------------------------------------------------
        if (defaultAndOptionalValues.default && defaultAndOptionalValues.default.length) {
            const defVal = defaultAndOptionalValues.default;
            const { optional } = defaultAndOptionalValues;
            for (let def of [...defVal, ...optional]) {
                if (def?.name && greyedDataItemList.indexOf(def.name) >= 0) {
                    if (!(dataSubTab !== null && AppService.hasRuleData(dataSubTab))) {
                        def.checked = false;
                    }
                } else if (def?.name && greyedAnalysesItemList.indexOf(def.name) >= 0) {
                    if (!(analysesTabData !== null && AppService.hasRuleData(analysesTabData, 'analyses'))) {
                        def.checked = false;
                    }
                } else if (def?.name && geneSetsItemList.indexOf(def.name) >= 0 && defaultAndOptionalValues.name === 'Gene Sets') {
                    if (!(analysesTabData !== null && AppService.hasRuleData(analysesTabData, 'analyses'))) {
                        def.checked = false;
                    }
                } else if (def?.name && covariationModuleItemList.indexOf(def.name) >= 0 && defaultAndOptionalValues.name === 'Covariation Modules') {
                    if (!(analysesTabData !== null && AppService.hasRuleData(analysesTabData, 'analyses'))) {
                        def.checked = false;
                    }
                } else if (def?.name && featureMatchOptionsList.indexOf(def.name) >= 0) {
                    let isEnabledFeatureOptions = dataSubTab?.filter(item =>item.name === 'Feature Attributes') && dataSubTab?.filter(item =>item.name === 'Feature Attributes')[0]?.importFeatureAttributeRules?.enableFeatureOptions;
                    let isMatchPercentSelected = dataSubTab?.filter(item =>item.name === 'Feature Attributes') && dataSubTab?.filter(item =>item.name === 'Feature Attributes')[0]?.importFeatureAttributeRules?.selectedFeatureMatchPercent;
                    if (def.name === 'Feature Match Percent') {
                        if(isEnabledFeatureOptions && isMatchPercentSelected)
                        def.checked = true;
                    else
                        def.checked = false;
                    } 
                    else if (def.name === 'Feature Match Count') {
                        if(isEnabledFeatureOptions)
                                def.checked = true;
                            else
                                def.checked = false;
                    } else if (!(dataSubTab !== null && AppService.hasRuleData(dataSubTab, 'features'))) {
                        def.checked = false;
                    }
                } else if(def?.name && featureSearchModuleDefinitionList.indexOf(def.name) >=0 && defaultAndOptionalValues.name === 'Covariation Modules'){
                    if(AppService.hasRuleData(dataSubTab, 'featureSearchModuleDefinition') && (AppService.hasRuleData(dataSubTab, 'featureInputID') || AppService.hasRuleData(dataSubTab, 'featureInputType'))) {
                        def.checked = true;
                    } else {
                        def.checked = false;
                    }
                } else if(def?.name && enrichMentAnalysisModuleDefinitionList.indexOf(def.name) >=0 && defaultAndOptionalValues.name === 'Covariation Modules'){
                    if(AppService.hasRuleData(analysesTabData, 'analyses')){
                        def.checked = true;
                    } else {
                        def.checked = false;
                    }
                }
            }
        }
        return attributeListData;
    },
    hasRuleData: (tabDataList = [], type = 'data') => {
        if (tabDataList && tabDataList.length) {
            for (let subTab of tabDataList) {
                if (type === 'data' && (subTab.name === "Sample Attributes" || subTab.tabName === "Sample Attributes") && (
                    (
                        subTab.importRules &&
                        subTab.importRules.rules &&
                        subTab.importRules.rules.length
                    ) ||
                    (
                        subTab.rules &&
                        subTab.rules.rules &&
                        subTab.rules.rules.length
                    ) ||
                    (
                        subTab.importSampleAttributeRules &&
                        subTab.importSampleAttributeRules.mutationAttributeQuery &&
                        subTab.importSampleAttributeRules.mutationAttributeQuery.rules &&
                        subTab.importSampleAttributeRules.mutationAttributeQuery.rules.length
                    )
                )) {
                    return true;
                } else if (type === 'analyses' && (subTab.name === "Gene Sets" || subTab.tabName === "Gene Sets") && (
                    (
                        subTab.importRules &&
                        subTab.importRules.rules &&
                        subTab.importRules.rules.length
                    ) ||
                    (
                        subTab.rules &&
                        subTab.rules.rules &&
                        subTab.rules.rules.length
                    )
                )) {
                    return true;
                } else if (type === 'gene-sets' && (subTab.name === "Covariation Modules" || subTab.tabName === "Covariation Modules") && (
                    (
                        subTab.importRules &&
                        subTab.importRules.rules &&
                        subTab.importRules.rules.length
                    ) ||
                    (
                        subTab.rules &&
                        subTab.rules.rules &&
                        subTab.rules.rules.length
                    )
                )) {
                    return true;
                } else if (type === 'features' && (subTab.name === "Feature Attributes" || subTab.tabName === "Feature Attributes") && (
                    (
                        subTab.importFeatureAttributeRules &&
                        subTab.importFeatureAttributeRules.enableFeatureOptions
                    ) ||
                    (
                        subTab.featureMatchPercentType
                    )
                )) {
                    return true;
                } else if ((type === 'featureMatchPercent') && (subTab.name === "Feature Attributes" || subTab.tabName === "Feature Attributes") && (
                    (
                        subTab.importFeatureAttributeRules &&
                        subTab.importFeatureAttributeRules.enableFeatureOptions &&
                        subTab.importFeatureAttributeRules.selectedFeatureMatchPercent
                    ) ||
                    (
                        subTab.featureMatchPercentType
                    )
                )) {
                    return true;
                } else if (type === 'featureMatchCount' && (subTab.name === "Feature Attributes" || subTab.tabName === "Feature Attributes") && (
                    (
                        subTab.importFeatureAttributeRules &&
                        subTab.importFeatureAttributeRules.enableFeatureOptions
                    ))) {
                    return true;
                }
                else if (type === 'featureSearchModuleDefinition' && (subTab.name === "Feature Attributes" || subTab.tabName === "Feature Attributes") && (
                    subTab?.importRules?.rules?.filter(item => item.label === 'Feature Search Module Definition').length > 0)) {
                    return true;
                } else if (type === 'featureInputID' && (subTab.name === "Feature Attributes" || subTab.tabName === "Feature Attributes") && (
                    subTab?.importRules?.rules?.filter(item => item.label === 'Feature Input ID') && subTab?.importRules?.rules?.filter(
                        item => item.label === 'Feature Input ID')[0] && subTab?.importRules?.rules?.filter(
                        item => item.label === 'Feature Input ID')[0].value?.value)) {
                    return true;
                } else if (type === 'featureInputType' && (subTab.name === "Feature Attributes" || subTab.tabName === "Feature Attributes") && (
                    subTab?.importRules?.rules?.filter(item => item.label === 'Feature Input Type') && subTab?.importRules?.rules?.filter(
                        item => item.label === 'Feature Input Type')[0] && subTab?.importRules?.rules?.filter(
                        item => item.label === 'Feature Input Type')[0].value?.value)) {
                    return true;
                }
    }
        }
        return false;
    },
    returnArray: (data, delimiter = ',') => {
        let dataArray = [];
        if (data && Array.isArray(data)) {
            dataArray = data;
        } else if (data && !Array.isArray(data)) {
            dataArray = data.split(delimiter);
        }
        return dataArray;
    },
    returnTermText: (searchTerm = '') => {
        if (isNaN(Number(searchTerm))) {
            return searchTerm;
        } else {
            if (searchTerm === '') {
                return searchTerm;
            }
            return Number(searchTerm);
        }
    },
    returnSideMenuListWithPermissions: (sideMenuList = [], currentUser) => {
        let sideMenuArrayList = [];
        let userData = currentUser || AuthService._getUser();
        if (userData && userData.permissions && userData.permissions.length) {
            const userPermissions = userData.permissions || [];
            if (sideMenuList && sideMenuList.length) {
                for (let menu of sideMenuList) {
                    const permissionSet = [];
                    PERMISSION_EXT.forEach(perm => {
                        const permission = `${convertPermType(menu.type)}${perm}`;
                        const hasPermission = (userPermissions.indexOf(permission) >= 0) ? true : false;
                        if (hasPermission) { permissionSet.push(permission); }
                    });
                    if (
                        (UP_ACCESS(permissionSet) &&
                        (
                            (menu?.type !== 'user-management' && userData.role === 'GU') ||
                            (userData.role !== 'GU')
                        )) || (menu?.type.includes('Notifications'))
                    ) {
                        sideMenuArrayList.push(menu);
                    }
                }
            }
        }
        return sideMenuArrayList;
    },
    hasAccess: (type, ext = '_VIEW') => {
        const permissionObject = {};
        if (AuthService._getUser()) {
            const userPermissions = AuthService._getUser().permissions || [];
            PERMISSION_EXT.forEach(perm => {
                const permission = `${convertPermType(type)}${perm}`;
                const hasPermission = (userPermissions.indexOf(permission) >= 0) ? true : false;
                permissionObject[perm] = hasPermission;
            });
            if (permissionObject[ext]) {
                return true;
            }
        }
        return false;
    },
    filterDetailViewWithPermissions: (arrayList = [], type = null, permLabel = 'permission') => {
        if (arrayList && arrayList.length) {
            const newArrayList = [];
            const typeMenuArrayList = [...arrayList];
            for (let item of typeMenuArrayList) {
                if (type) {
                    if (type === 'data-collections' && UP_ACCESS(item['dc_permission'])) {
                        newArrayList.push(item);
                    } else if (type === 'data-sets' && UP_ACCESS(item['ds_permission'])) {
                        newArrayList.push(item);
                    } else if (type === 'collections' && UP_ACCESS(item['dc_permission'])) {
                        newArrayList.push(item);
                    } else if ((['analyses', 'gene-sets'].indexOf(type) >= 0) && UP_ACCESS(item['permission'])) {
                        newArrayList.push(item);
                    }
                } else {
                    if (!item[permLabel]) {
                        newArrayList.push(item);
                    } else if (UP_ACCESS(item[permLabel])) {
                        newArrayList.push(item);
                    }
                }
            }
            return newArrayList;
        }
    },
    fetchVersionLength: (version = '') => {
        const versionStrArray = version.split('');
        let num = 0;
        versionStrArray.forEach(ver => {
            if (ver === '.') { num += 1; }
        });
        return num;
    },
    formContent: (
        label,              // 1
        dataKey,            // 2
        description,        // 3
        elementType,        // 4
        type = 'text',      // 5
        postKey,            // 6
        baseUrl,            // 7
        placeholder = '',   // 8
        disabled = false,   // 9
        value = '',         // 10
        required = false,   // 11
        valid = true,       // 12
        touched = false,    // 13
        values = [],        // 14
        id = ''             // 15
    ) => {
        const formData = {
            id,
            label,
            dataKey,
            postKey,
            description,
            elementType,
            elementConfig: { type, placeholder, disabled },
            value,
            validation: { required, message: '' },
            valid,
            touched
        };
        if (!postKey) { delete formData['postKey']; }
        if (!dataKey) { delete formData['dataKey']; }
        switch (elementType) {
            case 'text':
            case 'input':
                return { ...formData };
            case 'select':
                return { ...formData, values };
            case 'multiSelect':
                formData['elementConfig'] = null;
                return { ...formData, values };
            case 'multiLink':
                formData['elementConfig']['baseUrl'] = baseUrl;
                return { ...formData };
            default:
                return { ...formData };
        }
    },
    renderTreeElem: (browserName = '') => {
        if (browserName.toLowerCase().trim() === 'safari') {
            const element = document.querySelector('.rd3t-svg');
            if (element) {
                element.setAttribute('style', 'display: none');
                setTimeout(() => {
                    element.setAttribute('style', 'display: block');
                }, 150);
            }
        }
    },
    renderFrame: () => {
        const element = document.getElementById('file_content_viewer');
        if (element) {
            document.getElementById('file_content_viewer').src += '';
        }
    },
    downloadURL: (fileURL, fileName) => {
        if (!window.ActiveXObject) {
            var save = document.createElement('a');
            save.href = fileURL;
            save.target = '_blank';
            let filename = fileURL.substring(fileURL.lastIndexOf('/') + 1);
            save.download = fileName || filename;
            if (navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search("Chrome") < 0) {
                document.location = save.href;
                // window event not working here
            } else {
                var evt = new MouseEvent('click', {
                    'view': window,
                    'bubbles': true,
                    'cancelable': false
                });
                save.dispatchEvent(evt);
                (window.URL || window.webkitURL).revokeObjectURL(save.href);
            }
        }
    },
    getS3URLAndDownloadFile : (rowObject) => {  //create new URL for every click to avoid expired URLs
        AppService.getAwsCredentials().then(async res => {
            const s3Configuration = await {
                credentials: {
                    accessKeyId: res['AccessKeyId'],
                    secretAccessKey: res['SecretAccessKey'],
                    sessionToken: res['SessionToken']
                },
                region: res['region']
            };
            const s3 = await new S3Client(s3Configuration);
            const url = await getSignedUrl(s3, new GetObjectCommand({
                Bucket: res['bucket'],
                Key: rowObject['key']
            }), {
                expiresIn: 30 * 60
            });
            AppService.downloadURL(url);
        });
},
    revertEditComments: (comment, forReplies = false) => {
        if (((comment && typeof comment['editComment'] !== 'undefined') || (comment['replies'] && comment['replies'].length)) && !forReplies) {
            comment['editComment'] = false;
            if (comment['replies'] && comment['replies'].length) {
                for (let reply of comment['replies']) {
                    AppService.revertEditComments(reply, forReplies);
                }
            }
        } else {
            comment['isReplyOn'] = false;
            if (comment['replies'] && comment['replies'].length) {
                for (let reply of comment['replies']) {
                    AppService.revertEditComments(reply, forReplies);
                }
            }
        }
    },
    testForNumbersInInputField: (value) => {
        const re = /^[A-Za-z]+$/;
        if (!(re.test(value))) { return true; }
        return false;
    },
    removeClassName: (classNames = '', className = '') => {
        let newClassName = '', classArray = classNames.split(' ');
        classArray.forEach(cls => {
            if (cls !== className) {
                newClassName += `${cls} `;
            }
        });
        return newClassName.trim();
    },
    convertToJsonString: (data) => {
        let newObject = {};
        let keyArray = (obj) => Object.keys(obj);
        let isObject = (value) => (typeof value === 'object');
        let isArray = (value) => Array.isArray(value);
        if (data && typeof data !== 'string' && typeof data !== 'number' && keyArray(data) && keyArray(data).length) {
            keyArray(data).forEach((key, i) => {
                let dataValue = null;
                if (isArray(data[key])) {
                    dataValue = data[key].map(item => JSON.stringify(AppService.convertToJsonString(item)));
                } else if (isObject(data[key])) {
                    dataValue = JSON.stringify(AppService.convertToJsonString(data[key]));
                } else {
                    dataValue = data[key].toString();
                }
                newObject[key] = dataValue;
            });
            return newObject;
        }
        return data;
    },
    convertStrArrayToSeparatedStr: (array = [], separator = ',', type = null, key) => {
        let str = '';
        array.forEach((val, i) => {
            if (type === 'links') {
                let link = '';
                switch (key.toLowerCase()) {
                    case 'pubmed id':
                        link = `https://pubmed.ncbi.nlm.nih.gov/${val}`;
                        break;
                    case 'accession id':
                        link = `https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=${val}`;
                        if (val.includes('-')) {
                            link = `https://www.ebi.ac.uk/arrayexpress/experiments/${val}`;
                        }
                        break;
                    case 'bioproject id':
                        link = `https://www.ncbi.nlm.nih.gov/bioproject/${val}`;
                        break;
                    default:
                        break;
                }
                if (array.length === 1) {
                    val = "=HYPERLINK(" + "\"" + link + "\"" + ', ' + "\"" + val + "\"" + ")";
                } else {
                    val = link;
                }
            }
            str += (i < (array.length - 1)) ? `${val}${separator}` : val;
        });
        return str.trim();
    },
    reorderJSON: (order, obj) => {
        typeof order === 'string' && (order = order.split(/\s*,\s*/));
        return order.reduce((rslt, prop) => {
            rslt[prop] = obj[prop];
            return rslt;
        }, {});
    },
    checkForResponseKeys: (res, responseDataArray) => {
        for (let arrayVal of responseDataArray) {
            if (arrayVal && Object.keys(arrayVal).indexOf(res) > -1) {
                return true;
            }
        }
        return false;
    },
    findObject: (list = [], key = '', value = null) => list.find(el => (el[key] === value)),
    convertToDecimals: (n) => {
        if (n) {
            n = Number(n);
            let sign = (+n < 0) ? "-" : "", toStr = n.toString();
            if (!/e/i.test(toStr)) { return n; }
            let [lead, decimal, pow] = n.toString()
                .replace(/^-/, "")
                .replace(/^([0-9]+)(e.*)/, "$1.$2")
                .split(/e|\./);
            return (+pow < 0) ?
                sign + "0." + "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) + lead + decimal :
                sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))) : (decimal.slice(0, +pow) + "." + decimal.slice(+pow)))
        }
        return 0;
    },
    clearAllCookies: () => {
        let allCookies = document?.cookie?.split(';');
        if (allCookies && allCookies.length) {
            // for (var i = 0; i < allCookies.length; i++) {
            // document.cookie = allCookies[i] + "=;expires=" + new Date(0).toUTCString();
            for (let cookie of allCookies) {
                const eqPos = cookie.indexOf("=");
                const name = (eqPos > -1) ? cookie.substr(0, eqPos) : cookie;
                document.cookie = name + `=;expires=${new Date(0).toUTCString()}`;
            }
        }
    },
    gotoLink: (text, item) => {
        let component = (action) =>
            <span
                title="View sample queries"
                style={{ color: '#2390bb', cursor: 'pointer' }}
                onClick={() => action()}>
                {text}
            </span>;
        if (typeof item === 'string') {
            const onLinkClick = () => {
                window.open(item, '_blank');
            };
            return (component(onLinkClick));
        }
        return (component(item));
    },
    msToTime: (ms) => {
        let seconds = (ms / 1000).toFixed(2);
        let minutes = (ms / (1000 * 60)).toFixed(2);
        let hours = (ms / (1000 * 60 * 60)).toFixed(2);
        let days = (ms / (1000 * 60 * 60 * 24)).toFixed(2);
        if (seconds < 60) return `${seconds}${(Number(seconds) > 1) ? " seconds" : " second"}`;
        else if (minutes < 60) {
            let minStringArr = minutes.toString().split('.');
            if (typeof minStringArr[1] !== 'undefined' && typeof Number(minStringArr[1]) !== 'undefined') {
                return `${minStringArr[0]}${(Number(minStringArr[0]) > 1) ? (` minutes ${minStringArr[1]} ${(Number(minStringArr[1]) > 1) ? 'seconds' : 'second'}`) : " minute 0 seconds"}`;
            }
            return `${minutes}${(Number(minutes) > 1) ? " minutes" : " minute 0 seconds"}`;
        } else if (hours < 24) return `${hours}${(Number(hours) > 1) ? " hours" : " hour"}`;
        else return `${days}${(Number(days) > 1) ? " days" : " day"}`;
    },
    responseDataArrayFormat: (res) => {
        let responseObject = {};
        Object.keys(res).forEach(key => {
            if (key === 'attributes') {
                responseObject = { ...responseObject, ...res[key] };
            } else {
                responseObject[key] = res[key];
            }
        });
        return responseObject;
    },
    encryptData: (password) => {
        let encryptedPassword = password;
        /* if (password) {
            encryptedPassword = CryptoJS.AES.encrypt(
                password,
                CONST.OMICON_SECRET_PASS,
                { mode: CryptoJS.mode.ECB }
            );
        }
        return encryptedPassword.toString(); */
        return encryptedPassword;
    }
};

export default AppService;