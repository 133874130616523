import { appActionTypes } from "./app.types";

export const setLoader = (val, text = '') => ({
    type: appActionTypes.SET_LOADER_STATE,
    payload: { val, text }
});

export const setInLoader = (val) => ({
    type: appActionTypes.SET_IN_LOADER_STATE,
    payload: val
});

export const setAppId = (uuid) => ({
    type: appActionTypes.SET_APP_ID,
    payload: uuid
});

export const setUCSFWindow = (window) => ({
    type: appActionTypes.SET_WINDOW,
    payload: window
});

export const setUploadSuccessVal = (val) => ({
    type: appActionTypes.SET_COLLECTION_UPLOAD_SUCCESS_VAL,
    payload: val
});

export const setFileUploadStateReset = (val) => ({
    type: appActionTypes.SET_FILE_UPLOAD_RESET,
    payload: val
});

export const setUserErrorData = errorData => ({
    type: appActionTypes.SET_USER_ERROR,
    payload: errorData
});

export const setMainContentScrollData = scrollData => ({
    type: appActionTypes.SET_MAIN_CONTENT_SCROLL,
    payload: scrollData
});

export const setNetworkSearchVal = searchValue => ({
    type: appActionTypes.SET_NETWORK_SEARCH_VAL,
    payload: searchValue
});

export const setRouteTitleObject = (data) => ({
    type: appActionTypes.SET_ROUTE_TITLES,
    payload: data
});

export const setNetworkModeOn = (data) => ({
    type: appActionTypes.SET_NETWORK_MODE,
    payload: data
});

export const setHomePageClick = (data) => ({
    type: appActionTypes.SET_HOME_PAGE_CLICK,
    payload: data
});

export const setCommentsCount = (data) => ({
    type: appActionTypes.SET_COMMENTS_COUNT,
    payload: data
});

export const setNotifData = (data) => ({
    type: appActionTypes.SET_NOTIF_DATA,
    payload: data
});

export const setLandingPageSamples = (data) => ({
    type: appActionTypes.SET_LANDING_PAGE_SAMPLES,
    payload: data
});

export const setSystemTablesData = (data) => ({
    type: appActionTypes.SET_SYSTEM_TABLES_DATA,
    payload: data
});

export const setRefreshAppId = (data) => ({
    type: appActionTypes.SET_REFRESH_APP_ID,
    payload: data
});

export const setRefreshWindow = (data) => ({
    type: appActionTypes.SET_REFRESH_WINDOW,
    payload: data
});

export const setFilesCommentsCount = (data) => ({
    type: appActionTypes.SET_FILES_COMMENT_COUNT,
    payload: data
});

