import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import CommentsPage from './comments.components';
import AppService from '../../../core/services/app-service';
import Loader from '../../../assets/loaders/spinner-blue.gif';

import './side-panel.scss';

const SidePanel = (props) => {
    const { isSidePanelOpen, selectedFile, setSelectedFile, setIsOpen, isOpen, closePanel } = props;
    const history = useHistory();
    const location = useLocation();

    const [isLoading, setIsLoading] = useState(false);
    const queryParams = AppService.fetchQueryParams(props?.location?.search);
    const [isCommentAddedOrDeleted, setIsCommentAddedOrDeleted] = useState(false);
    const file_folder_name = queryParams?.file_folder_name;
    useEffect(() => {
        file_folder_name && setIsOpen(true);
    }, [file_folder_name]);

    return (
        <section className={`right-side-panel ${isSidePanelOpen || isOpen ? 'open' : ''}`}>
            <div className={`overlay-content-wrapper ${isLoading ? 'overlay-fixed':''}`}>
            {isLoading && <div className="overlay">
            <div className="loading-spinner">
                <img src={Loader} alt="loader" />
            </div></div>}
            <div className='header-content-wrapper'>
            <div className='header-container'>
                <span className='close-btn' onClick={() => {
                    closePanel();
                    setTimeout(() => {
                        const searchParams = new URLSearchParams(location.search);
                        if(searchParams?.has('file_folder_name')) {
                            searchParams.delete('file_folder_name');
                            history.replace(`${location.pathname}?${searchParams.toString()}`);
                        } else {
                            isCommentAddedOrDeleted && window.location.reload();
                        }
                    }, 500);
                }}></span>
            </div>
            <CommentsPage {...props} selectedFile={selectedFile} setSelectedFile={setSelectedFile} setIsLoading={setIsLoading} isCommentAddedOrDeleted={isCommentAddedOrDeleted} setIsCommentAddedOrDeleted={setIsCommentAddedOrDeleted} />
            </div>
            </div>
        </section>
    );
};

export default SidePanel;
