/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useContext } from 'react';
import useState from 'react-usestateref';
import { connect, useDispatch } from 'react-redux';
import { ucsfInstance } from '../../../core/axios/ucsf';
import CustomButton from '../../../core/components/custom-button/custom-button.component';
import AppService from '../../../core/services/app-service';
import { listData, selectFormInput } from './comments.data';
import './comments.styles.scss';
import { createStructuredSelector } from 'reselect';
import { getPossibleValues } from '../../../redux/collections/collections.selectors';
import { MentionsInput, Mention } from 'react-mentions';
import moment from 'moment';
import { Fragment } from 'react';
import { UP_ACCESS } from '../../../core/utils/permissions';
import AuthService from '../../../core/services/authentication-service';
import { getUsersList } from '../../../redux/data-saver/data-saver.selectors';
import { setFilesCommentsCount } from '../../../redux/app/app.actions';
import { getFilesCommentsCount } from '../../../redux/app/app.selectors';
import { setUsersList } from '../../../redux/data-saver/data-saver.actions';
import { COMPONENT_APIS } from '../../../core/utils/omi-api';
import { DataContext } from "../../../App";

const inputFormData = {
    description: '',
    elementType: 'textArea',
    elementConfig: {
        type: 'text',
        placeholder: '',
        disabled: false
    },
    value: '',
    validation: {
        required: false
    },
    valid: false,
    touched: false
};

const CommentsPage = (props) => {
    const {
        location,
        possibleValues,
        data,
        selectedFile,
        setSelectedFile,
        setIsLoading,
        setIsCommentAddedOrDeleted,
        fileCommentsCount
    } = props;
    let { dsAttributes } = possibleValues || {};
    const [comments, setComments] = useState([]); // ...commentsArray
    const [childrenComments, setChildrenComments] = useState([]);
    const [mainComment, setMainComment, mainCommentRef] = useState({});

    const [joinDiscussion, setJoinDiscussion] = useState(false);
    const [addComment, setCommentChange] = useState({
        commentField: { ...inputFormData }
    });
    const [collaborator, setCollaborator] = useState({
        collaboratorField: { ...selectFormInput }
    });
    const [visibility, setVisibility] = useState({
        visibilityField: { ...selectFormInput }
    });
    const [listsObject, setListsObject] = useState({
        ...listData
    });
    const [fileDisplayName, setFileDisplayName] = useState('');
    const [analystArray, setAnalystArray] = useState([]);
    const [mentions, setMentions] = useState([]);
    const dp = useDispatch();
    const queryParams = AppService.fetchQueryParams(location?.search);
    const iD = queryParams?.contentType === 'analyses' ?  queryParams.analysisId : queryParams?.dsId ? queryParams?.dsId: queryParams?.dcId;
    const entityId = queryParams?.contentType === 'analyses' ? 'project' : queryParams?.dsId ? 'ds' : 'dc';
    const levelLimit = 3;
    const userRole = AuthService._getUserRole();
    const { commentsLength, selectedModuleSnapshot } = useContext(DataContext);
    const commentAPIS = {
        GET_ALL: `/api/file-comments/${iD}`,
        CREATE_COMMENT: `/api/comment/create/`,
        CREATE_REPLY: `/api/reply/create/`,
        REPLIES: `/api/replies`,
        UPDATE_COMMENT: `/api/comment`,
        UPDATE_REPLY: `/api/reply`,
        DELETE_REPLY: (id) => `/api/reply/${id}/delete`,
        COMMENTS_COUNT: (id) => `/api/file-comments-count/${id}`
    };

    const regex = /(?:^|\s)(@([\s\S]+))$/;

    useEffect(() => {
        selectValue(listsObject['visibilityField'][1], visibility, 'visibilityField');
        ucsfInstance.get(COMPONENT_APIS.USERS).then(async users => {
            fetchAnalystsForMentions(JSON.parse(JSON.stringify(users.data)));
        }).catch(error => error);
        data?.key && setSelectedFile(data?.key);
    }, [data]);

    useEffect(() => {
        if(localStorage.getItem('parent')) {
            let element = document.getElementById(`side-panel-parent-comment-${localStorage.getItem('parent')}`);
            if(element) {
                element.scrollIntoView({behavior: 'smooth'});
                element.classList.add("highlight-side-panel-main-comment");
                setTimeout(function () {
                    localStorage.removeItem('parent');
                    element.classList.add("highlight-side-panel-main-comment");
                }, 5000);
            };
        }
    }, [document.getElementById(`side-panel-parent-comment-${localStorage.getItem('parent')}`)]);

    useEffect(() => {    // click from notification, then call the comments list with file or folder key in url
        queryParams?.file_folder_name && setSelectedFile(queryParams?.file_folder_name);
    }, [queryParams?.file_folder_name]);

    useEffect(() => {
        selectedFile && fetchComments('', '', selectedFile);
        if(!selectedFile && data?.key) {
            selectedFile && fetchComments('', '', data?.key);
        }
        
    }, [selectedFile])


    useEffect(() => {
        selectedModuleSnapshot && fetchComments('', '', selectedModuleSnapshot);
    }, [commentsLength])

    const fetchComments = (limit = 10, offset = 0, key) => {
        setComments([]);
        setIsLoading(true);
        ucsfInstance.get(`${commentAPIS.COMMENTS_COUNT(iD)}?key=${key}`).then((res) => {
            dp(setFilesCommentsCount(res?.data?.total_comments));
        });
        ucsfInstance.get(`${commentAPIS.GET_ALL}/?limit=${limit}&offset=${offset}&key=${key}`).then(commentRes => {
            setFileDisplayName(commentRes?.data?.display_name);
            let commentsList = commentRes.data[key] && [...commentRes.data[key]];
            commentsList?.map(comment => {
                comment['level'] = 0;
                comment = { ...serializeComment(comment) };
                comment['replies_count'] = 0;
                return comment;
            });
            let childrenCommentsList = [...commentRes.data['children']];
            childrenCommentsList.map(comment => {
                // comment['level'] = 0;
                // comment = { ...serializeComment(comment) };
                // comment['replies_count'] = 0;
                // return comment;
            });
            setJoinDiscussion(false);
            setComments(commentsList);
            setChildrenComments(childrenCommentsList);
            setIsLoading(false);
        }).catch(err => {
            console.log(err);
            setIsLoading(false);
        });
    }

    const serializeComment = (comment) => {
        comment['textField'] = { ...inputFormData };
        comment['editCommentField'] = { ...inputFormData };
        comment['editCommentField']['originalVal'] = comment.message;
        comment['editCommentField']['value'] = comment.message;
        comment['history'] = moment(comment.updated_at).startOf('second').fromNow();
        comment['level'] = (typeof comment['level'] !== 'undefined') ? comment['level'] : 0;
        return comment;
    }

    const onReplyClick = (reply, type = null) => {
        let commentsList = [...comments];
        commentsList.map(cmt => {
            AppService.revertEditComments(cmt, true);
            AppService.revertEditComments(cmt);
            replyLevelEdit(cmt, reply, type);
            return cmt;
        });
        setComments(commentsList);
    }

    const replyLevelEdit = (cmt, reply, type) => {
        if (cmt['replies'] && cmt['replies'].length && reply['level'] > 0) {
            let found = false;
            cmt['replies'].forEach(rply => {
                if (rply['level'] === reply['level']) { found = true; }
            });
            if (found) {
                cmt['replies'].map(replyCmt => {
                    if ((type === null || typeof type === 'undefined') && reply.id === replyCmt.id) {
                        replyCmt['textField']['value'] = '';
                        replyCmt.isReplyOn = true;
                    }
                    return replyCmt;
                });
            } else {
                for (let innerCmt of cmt['replies']) {
                    replyLevelEdit(innerCmt, reply);
                }
            }
        } else {
            if (cmt['id'] === reply['id']) {
                cmt.isReplyOn = true;
            }
        }
    }

    const inputChangedHandler = (
        { event, comment },
        identifier
    ) => {
        let commentList = [...comments];
        commentList.map(commentObj => {
            const updatedCommentObj = { ...commentObj };
            if (updatedCommentObj.id === comment.id) {
                const updatedFormElement = { ...updatedCommentObj[identifier] };
                if (typeof event === 'string') {
                    updatedFormElement.value = event;
                } else {
                    updatedFormElement.value = event.target.value;
                }
                updatedFormElement.touched = true;
                commentObj[identifier] = updatedFormElement;
            }
            return commentObj;
        });
        setComments(commentList);
    }

    const replyInputChangedHandler = (
        { event, reply },
        identifier
    ) => {
        const mainComments = [...comments];
        mainComments.map(cmt => {
            levelReplyInputChangeHandler(cmt, reply, event, identifier);
            return cmt;
        });
        setComments(mainComments);
    }

    const levelReplyInputChangeHandler = (cmt, reply, event, identifier) => {
        if (cmt['replies'] && cmt['replies'].length) {
            let found = false;
            cmt['replies'].forEach(rply => {
                if (rply['level'] === reply['level']) { found = true; }
            });
            if (found) {
                cmt['replies'].map(replyCmt => {
                    const updatedCommentObj = { ...replyCmt };
                    if (replyCmt.id === reply.id) {
                        const updatedFormElement = { ...updatedCommentObj[identifier] };
                        updatedFormElement.value = event.target.value;
                        updatedFormElement.touched = true;
                        replyCmt[identifier] = updatedFormElement;
                    }
                    return replyCmt;
                });
            } else {
                for (let innerCmt of cmt['replies']) {
                    levelReplyInputChangeHandler(innerCmt, reply, event, identifier);
                }
            }
        }
    }

    const commentChangedHandler = (
        event,
        identifier
    ) => {
        const updatedCommentObj = { ...addComment };
        const updatedFormElement = { ...updatedCommentObj[identifier] };
        updatedFormElement.value = event.target.value;
        updatedFormElement.touched = true;
        updatedCommentObj[identifier] = updatedFormElement;
        setCommentChange(updatedCommentObj);
    }

    const onReply = (comment) => {
        setIsLoading(true);
        let parser = new DOMParser();
        let parsedMentions = [];
        let doc = parser.parseFromString(comment.textField.value, "text/html");
        doc.querySelectorAll("span").forEach(s => parsedMentions.push(s.innerHTML));
        const uniqueMentions = parsedMentions.filter((item,
            index) => parsedMentions.indexOf(item) === index);
        const mentionIds = uniqueMentions.map((mention) => {
            const matchedUsers = analystArray.find((user) => {
                return user.display === mention?.replace('@', '');
            })
            return matchedUsers
        }).filter(Boolean).map((el) => {
            return el.user
        });
        const commentBody = {
            "level": comment.level + 1,
            "comment": comment.id,
            "visibility": visibility.visibilityField.value.toLowerCase(),
            "message": comment.textField.value,
            "to_user": null,
            // "created_by": null
            "mentioned_users": mentionIds,
            "comment_page_url": `${window.location.href}&file_folder_name=${data?.key || selectedFile}`,
            "entity_id": iD,
            "entity_type": entityId
        };
        ucsfInstance.post(commentAPIS.CREATE_REPLY, commentBody).then(res => {
            setIsLoading(false);
            onReplyEdit(comment, 'undo');
            viewReplies(comment);
            setMentions([]);
        }).catch(err => {
            setIsLoading(false);
        });
    }

    const onCancel = (comment) => {
        const commentList = [...comments];
        commentList.map(commentObj => {
            AppService.revertEditComments(commentObj, true);
            AppService.revertEditComments(commentObj);
            if (commentObj.id === comment.id) {
                commentObj.textField['value'] = '';
            }
            return commentObj;
        });
        setComments(commentList);
    }

    const onMainCommentCancel = (
        event,
        form,
        identifier
    ) => {
        const updatedFormObj = { ...form };
        const updatedFormElement = { ...updatedFormObj[identifier] };
        updatedFormElement.value = event; // .target.value;
        updatedFormElement.touched = true;
        updatedFormObj[identifier] = updatedFormElement;
        setCommentChange(updatedFormObj);
    }

    const onJoinDiscussionClick = () => {
        setJoinDiscussion(true);
        onMainCommentCancel('', addComment, 'commentField');
        selectValue('Public', visibility, 'visibilityField');
    }

    const viewReplies = (replyComment, type = 'show') => {
        setIsLoading(true);
        ucsfInstance.get(`${commentAPIS.REPLIES}/${replyComment.id}/`).then(async res => {
            const mainComments = await [...comments];
            await mainComments.forEach(cmt => {
                AppService.revertEditComments(cmt, true);
                AppService.revertEditComments(cmt);
            });
            if (typeof replyComment['mainCommentId'] !== 'undefined') {
                await mainComments.map(cmt => {
                    viewLevelReplies(cmt, replyComment, res.data, type);
                    return cmt;
                });
            } else { // await setMainComment(replyComment);
                await mainComments.map(cmt => { // cmt['showReplies'] = false;
                    if (type === 'show' && cmt.id === replyComment.id && replyComment.level < 2) {
                        let replyArray = [...res.data];
                        replyArray.map(reply => {
                            let levelZeroCommentId = mainComments.find(mainComment => (mainComment.level === 0)).id;
                            reply['mainCommentId'] = mainCommentRef.current.id ? mainCommentRef.current.id : levelZeroCommentId;
                            reply['parentId'] = reply.comment; // cmt['id'];
                            if (reply.level === 1) {
                                reply['mainCommentId'] = reply.comment;
                            }
                            reply = { ...serializeComment(reply) };
                            return reply;
                        });
                        cmt['replies_count'] = replyArray.length ? replyArray.length : 0;
                        cmt['replies'] = replyArray;
                        cmt['showReplies'] = true;
                    } else if (type === 'hide' && cmt.id === replyComment.id && replyComment.level < 2) {
                        cmt['showReplies'] = false;
                    } else {
                        viewLevelReplies(cmt, replyComment, res.data, type);
                    }
                    cmt = { ...serializeComment(cmt) };
                    return cmt;
                });
            }
            setComments(mainComments);
           setIsLoading(false);
        }).then(err => {
            setIsLoading(false);
        });
    }

    const viewLevelReplies = (cmt, reply, resultantArray, type) => {
        if (cmt['replies'] && cmt['replies'].length) {
            let found = false; // cmt['showReplies'] = false;
            cmt['replies'].forEach(rply => {
                if (rply['level'] === reply['level']) { found = true; }
            });
            if (found) {
                cmt['replies'].map(replyCmt => { // replyCmt['showReplies'] = false;
                    if (type === 'show' && replyCmt.id === reply.id) {
                        resultantArray.map(resData => {
                            resData['mainCommentId'] = mainCommentRef.current.id; // mainComment.id;
                            resData['parentId'] = resData.comment;
                            resData = { ...serializeComment(resData) };
                            return resData;
                        });
                        replyCmt['replies_count'] = resultantArray.length ? resultantArray.length : 0;
                        replyCmt['replies'] = resultantArray;
                        replyCmt['showReplies'] = true;
                    } else if (type === 'hide' && replyCmt.id === reply.id) {
                        replyCmt['showReplies'] = false;
                    }
                    return replyCmt;
                });
            } else {
                for (let innerCmt of cmt['replies']) {
                    viewLevelReplies(innerCmt, reply, resultantArray, type);
                } // cmt['showReplies'] = true;
            }
        }
    }

    const editComment = (comment, type = null) => {
        let commentArray = [...comments];
        commentArray.map(cmt => {
            AppService.revertEditComments(cmt, true);
            AppService.revertEditComments(cmt);
            if ((type === null || typeof type === 'undefined') && comment.id === cmt.id) {
                cmt['editComment'] = true;
            } else if (type === 'undo' && comment.id === cmt.id) {
                cmt['editCommentField']['value'] = cmt['editCommentField']['originalVal'];
            }
            return cmt;
        });
        setComments(commentArray);
    }

    const onCommentUpdate = (comment) => {
        setIsLoading(true);
        let parser = new DOMParser();
        let parsedMentions = [];
        let doc = parser.parseFromString(comment.editCommentField.value, "text/html");
        doc.querySelectorAll("span").forEach(s => parsedMentions.push(s.innerHTML));
        const uniqueMentions = parsedMentions.filter((item,
            index) => parsedMentions.indexOf(item) === index);
        const mentionIds = uniqueMentions.map((mention) => {
            const matchedUsers = analystArray.find((user) => {
                return user.display === mention?.replace('@', '');
            })
            return matchedUsers
        }).filter(Boolean).map((el) => {
            return el.user
        });
        const commentBody = {
            "level": comment.level,
            "id": comment.id,
            "entity_id": iD,
            "visibility": visibility.visibilityField.value.toLowerCase(),
            "message": comment.editCommentField.value,
            "to_user": null,
            "entity_type": entityId,
            "mentioned_users": mentionIds,
            "comment_page_url": `${window.location.href}&file_folder_name=${data?.key || selectedFile}`,
            "file_folder_name": data?.key || selectedFile

        };
        ucsfInstance.put(`${commentAPIS.UPDATE_COMMENT}/${comment.id}/`, commentBody).then(res => {
            fetchComments('', '',selectedFile);
        }).catch(err => {
            setIsLoading(false);
        });
    }

    const onReplyUpdate = (comment, reply) => {
        setIsLoading(true);
        let parser = new DOMParser();
        let parsedMentions = [];
        let doc = parser.parseFromString(reply.editCommentField.value, "text/html");
        doc.querySelectorAll("span").forEach(s => parsedMentions.push(s.innerHTML));
        const uniqueMentions = parsedMentions.filter((item,
            index) => parsedMentions.indexOf(item) === index);
        const mentionIds = uniqueMentions.map((mention) => {
            const matchedUsers = analystArray.find((user) => {
                return user.display === mention?.replace('@', '');
            })
            return matchedUsers
        }).filter(Boolean).map((el) => {
            return el.user
        });
        const commentBody = {
            "id": reply.id,
            "entity_id": iD,
            "visibility": visibility.visibilityField.value.toLowerCase(),
            "message": reply.editCommentField.value,
            "comment": comment.id,
            "level": reply.level,
            "to_user": null,
            "entity_type": entityId,
            "mentioned_users": mentionIds,
            "comment_page_url": `${window.location.href}&file_folder_name=${data?.key || selectedFile}`
        };
        ucsfInstance.put(`${commentAPIS.UPDATE_REPLY}/${reply.id}/`, commentBody).then(res => {
            onReplyEdit(comment, 'undo');
            viewReplies(comment);
            setIsLoading(false);
        }).catch(err => {
            setIsLoading(false);
        });
    }

    const selectValue = (
        event,
        form,
        identifier
    ) => {
        const updatedFormObj = { ...form };
        const updatedFormElement = { ...updatedFormObj[identifier] };
        updatedFormElement.value = event; // .target.value;
        updatedFormElement.touched = true;
        updatedFormObj[identifier] = updatedFormElement;
        if (identifier === 'visibilityField') {
            setVisibility(updatedFormObj);
        } else {
            setCollaborator(updatedFormObj);
        }
    }

    const onAddNewComment = (level) => {
        setIsLoading(true);
        let parser = new DOMParser();
        let parsedMentions = [];
        let doc = parser.parseFromString(addComment.commentField.value, "text/html");
        doc.querySelectorAll("span").forEach(s => parsedMentions.push(s.innerHTML));
        const uniqueMentions = parsedMentions.filter((item,
            index) => parsedMentions.indexOf(item) === index);
        const mentionIds = uniqueMentions.map((mention) => {
            const matchedUsers = analystArray.find((user) => {
                return user.display === mention?.replace('@', '');
            })
            return matchedUsers
        }).filter(Boolean).map((el) => {
            return el.user
        });
        const commentBody = {
            "entity_type": entityId,
            "entity_id": iD,
            "level": level,
            "visibility": visibility.visibilityField.value.toLowerCase(),
            "message": addComment.commentField.value,
            "to_user": null,
            "comment": null,
            "mentioned_users": mentionIds,
            "comment_page_url": `${window.location.href}&file_folder_name=${data?.key || selectedFile}`,
            "file_folder_name": data?.key || selectedFile

        };
        ucsfInstance.post(commentAPIS.CREATE_COMMENT, commentBody).then(res => {
            fetchComments('', '',data?.key || selectedFile);
            setMentions([]);
            setIsCommentAddedOrDeleted(true);
        }).catch(err => {
            setIsLoading(false);
        });
    }

    const onReplyEdit = (reply, type = null) => {
        const mainComments = [...comments];
        mainComments.map(cmt => {
            AppService.revertEditComments(cmt, true);
            AppService.revertEditComments(cmt);
            replyEdit(cmt, reply, type);
            return cmt;
        });
        setComments(mainComments);
    }

    const replyEdit = (cmt, reply, type) => {
        if (cmt['replies'] && cmt['replies'].length && reply['level'] > 0) {
            let found = false;
            cmt['replies'].forEach(rply => {
                if (rply['level'] === reply['level']) { found = true; }
            });
            if (found) {
                cmt['replies'].map(replyCmt => {
                    if ((type === null || typeof type === 'undefined') && reply.id === replyCmt.id) {
                        replyCmt['editComment'] = true;
                    } else if (type === 'undo' && reply.id === replyCmt.id) {
                        replyCmt['editCommentField']['value'] = replyCmt['editCommentField']['originalVal'];
                    }
                    return replyCmt;
                });
            } else {
                for (let innerCmt of cmt['replies']) {
                    replyEdit(innerCmt, reply, type);
                }
            }
        }
    }

    const fetchAnalystsForMentions = (allUsers) => {
        let users = [];
        if (allUsers && allUsers.length) {
            users = allUsers.map((user) => {
                let obj = { id: `@${user.name.toLowerCase()}${user.user}`, display: user.name, user: user.user };
                return obj;
            });
        } else if (dsAttributes) {
            let dsAnalystData = dsAttributes.find(attribute => (attribute.name === 'Analyst'));
            if (dsAnalystData && dsAnalystData.possible_values && dsAnalystData.possible_values.length) {
                users = dsAnalystData.possible_values.map((analyst) => {
                    let obj = { id: `@${analyst.toLowerCase()}`, display: analyst };
                    return obj;
                });
            }
        }
        setAnalystArray([...users]);
    }

    const deleteReply = (comment, reply) => {
        setIsLoading(true);
        ucsfInstance.delete(commentAPIS.DELETE_REPLY(reply.id)).then(res => {
            // onReplyEdit(comment, 'undo');
            if (comment) {
                viewReplies(comment);
            } else {
                fetchComments('', '',selectedFile);
            }
            setIsCommentAddedOrDeleted(true);
        }).catch(err => {
            setIsLoading(false);
        });
    }
// Style specifically for the mentioned text (color blue for selected mentions)
const mentionTextStyle = {
    color: '#2390bb',       // Blue text color for mentions
    fontSize: '15px'     
  };
    const repliesTemplate = (comment, type = 'comment') => {
        return comment['showReplies'] && comment['replies'] && comment['replies'].length ?
            comment['replies'].map(reply => {
                return (
                    <div key={reply.id} className={`side-panel-content replies${reply?.isReplyOn ? ' reply-on' : ''}`}>
                        <div className="user-img"></div>
                        <div className="user-comment-box">
                            <div className="user-name">{reply.user}</div>
                            {
                                !reply['editComment'] ?
                                    <Fragment>
                                        <div
                                            onClick={() => {
                                                if ((UP_ACCESS(['REPLIES_UPDATE', 'REPLIES_UPDATE_SELF', 'PROJECT_COMMENTS_MODIFY_SELF']) && reply['is_owner']) || userRole === 'admin') { onReplyEdit(reply); }
                                            }}
                                            title="Edit reply"
                                            className={`user-comment${!reply.user ? ' no-user' : ''}`}
                                            dangerouslySetInnerHTML={{ __html: reply.message }}>
                                        </div>
                                        <div className="info">
                                            <div className="info-timeline">{reply.created_by_name} replied {reply.history}</div>
                                            <div className="reply-delete-wrapper">{
                                                (
                                                    reply['level'] !== levelLimit &&
                                                    ((UP_ACCESS(['REPLIES_CREATE', 'REPLIES_UPDATE_SELF', 'PROJECT_COMMENTS_MODIFY_SELF']) || reply['is_owner']) || userRole === 'admin')
                                                ) ? // && !reply['is_owner'] ?
                                                    <Fragment>
                                                        <div className="info-oval"></div>
                                                        <div className="info-reply" onClick={() => onReplyClick(reply)}>Reply</div>
                                                    </Fragment> :
                                                    null
                                            }
                                            {
                                                (!reply['showReplies'] && reply['level'] !== levelLimit && reply['replies_count']) && UP_ACCESS(['REPLIES_LIST_VIEW']) ?
                                                    <Fragment>
                                                        <div className="info-oval"></div>
                                                        <div className="info-reply" onClick={() => {
                                                            if (type === 'comment') { setMainComment(comment); }
                                                            viewReplies(reply, 'show');
                                                        }}>
                                                            {reply['replies_count'] === 1 ? 'View Reply' : 'View Replies'}
                                                        </div>
                                                    </Fragment> :
                                                    reply['level'] !== 5 && reply['replies_count'] && UP_ACCESS(['REPLIES_LIST_VIEW']) ?
                                                        <Fragment>
                                                            <div className="info-oval"></div>
                                                            <div className="info-reply" onClick={() => {
                                                                if (type === 'comment') { setMainComment(comment); }
                                                                viewReplies(reply, 'hide');
                                                            }}>
                                                                {reply['replies_count'] === 1 ? 'Hide Reply' : 'Hide Replies'}
                                                            </div>
                                                        </Fragment> :
                                                        null
                                            }
                                            {
                                                (UP_ACCESS(['REPLIES_DELETE', 'REPLIES_DELETE_SELF', 'PROJECT_COMMENTS_DELETE_SELF']) && (comment['is_owner'] || reply['is_owner'])) || userRole === 'admin' ?
                                                    <Fragment>
                                                        <div className="info-oval"></div>
                                                        <div className="info-reply" onClick={() => {
                                                            if (UP_ACCESS(['REPLIES_DELETE_SELF', 'PROJECT_COMMENTS_DELETE_SELF']) || userRole === 'admin') { deleteReply(comment, reply); }
                                                        }}>Delete</div>
                                                    </Fragment> :
                                                    null
                                            }</div>
                                        </div>
                                    </Fragment> :
                                    <div className={`comment-box${!reply.user ? ' no-user' : ''}`}>
                                        <div className="txt-area">
                                            <form>
                                                <MentionsInput
                                                    id={reply.id}
                                                    spellCheck={false}
                                                    className="mention"
                                                    value={reply.editCommentField.value}
                                                    allowSpaceInQuery={true}
                                                    allowSuggestionsAboveCursor={true}
                                                    onChange={(event) => replyInputChangedHandler({ event, reply }, 'editCommentField')}>
                                                    <Mention
                                                        trigger={regex}
                                                        appendSpaceOnAdd={true}
                                                        markup={`<span class="mention-link">@__display__</span>`}
                                                        onAdd={(id, display,) => {
                                                            setMentions([...mentions, display]);
                                                        }}
                                                        style={mentionTextStyle}
                                                        data={analystArray} />
                                                </MentionsInput>
                                            </form>
                                        </div>
                                        {
                                            (UP_ACCESS(['REPLIES_UPDATE', 'REPLIES_UPDATE_SELF', 'PROJECT_COMMENTS_MODIFY_SELF']) && (comment['is_owner'] || reply['is_owner'])) || userRole === 'admin' ?
                                                <div className="btn-group">
                                                    <div className="lft">
                                                        <CustomButton
                                                            inverted="inverted"
                                                            click={() => onReplyUpdate(comment, reply)}
                                                            style={{
                                                                height: '28px',
                                                                minWidth: '105px',
                                                                marginTop: '10px'
                                                            }}>
                                                            UPDATE
                                                        </CustomButton>
                                                        <div className="cancel" onClick={() => onReplyEdit(reply, 'undo')}>
                                                            Cancel
                                                        </div>
                                                    </div>
                                                </div> :
                                                null
                                        }
                                    </div>
                            }
                            {
                                reply?.textField && reply?.isReplyOn && ((UP_ACCESS(['REPLIES_CREATE', 'REPLIES_UPDATE_SELF', 'PROJECT_COMMENTS_MODIFY_SELF']) || reply['is_owner']) || userRole === 'admin') ?
                                    <div className="comment-box">
                                        <div className="txt-area">
                                            <MentionsInput
                                                className="mention"
                                                spellCheck={false}
                                                value={reply.textField.value}
                                                allowSpaceInQuery={true}
                                                allowSuggestionsAboveCursor={true}
                                                // onChange={(event) => inputChangedHandler({ event, reply }, 'textField')}>
                                                onChange={(event) => replyInputChangedHandler({ event, reply }, 'textField')}>
                                                <Mention
                                                    trigger={regex}
                                                    appendSpaceOnAdd={true}
                                                    markup={`<span class="mention-link">@__display__</span>`}
                                                    onAdd={(id, display,) => {
                                                        setMentions([...mentions, display]);
                                                    }}
                                                    style={mentionTextStyle}
                                                    data={analystArray} />
                                            </MentionsInput>
                                        </div>
                                        <div className="btn-group">
                                            <div className="lft">
                                                <CustomButton
                                                    inverted="inverted"
                                                    click={() => onReply(reply)}
                                                    style={{
                                                        height: '22px',
                                                        minWidth: '65px',
                                                        marginTop: '10px'
                                                    }}>
                                                    POST
                                                </CustomButton>
                                                {/* <div className="cancel" onClick={() => onReplyClick(reply, 'cancel')}> */}
                                                <div className="cancel" onClick={() => onReplyEdit(reply, 'undo')}>
                                                    Cancel
                                                </div>
                                            </div>
                                        </div>
                                    </div> : null
                            }
                            {
                                reply['showReplies'] && reply['replies'] && reply['replies'].length ?
                                    repliesTemplate(reply, 'reply') :
                                    null
                            }
                        </div>
                    </div>
                )
            }) :
            null
    }
    return (
        <div className="comments-container">
            <span>comments for <span className="file-name-heading">{`${fileDisplayName} (${fileCommentsCount})`}</span></span>
            <div className="add-comment">
                <div className="side-panel-content comment">
                    <div className="user-img"></div>
                    <div className={`user-comment-box${joinDiscussion ? ' comment' : ''}`}>
                        {
                            !joinDiscussion ?
                                <div className="discussion" onClick={() => {
                                    if (UP_ACCESS(['COMMENTS_CREATE'])) { onJoinDiscussionClick(); }
                                }}> Join the discussion... </div> :
                                <Fragment>
                                    <div className="comment-box comment">
                                        <div className="txt-area">
                                            <MentionsInput
                                                className="mention"
                                                spellCheck={false}
                                                value={addComment.commentField.value}
                                                allowSpaceInQuery={true}
                                                allowSuggestionsAboveCursor={true}
                                                onChange={(event) => commentChangedHandler(event, 'commentField')}>
                                                <Mention
                                                    trigger={regex}
                                                    appendSpaceOnAdd={true}
                                                    markup={`<span class="mention-link">@__display__</span>`}
                                                    onAdd={(id, display) => {
                                                        setMentions([...mentions, display]);
                                                    }}
                                                    style={mentionTextStyle}
                                                    data={analystArray} />
                                            </MentionsInput>
                                        </div>
                                        <div className="btn-group">
                                            {
                                                UP_ACCESS(['COMMENTS_CREATE']) ?
                                                    <div className="lft">
                                                        <CustomButton
                                                            inverted="inverted"
                                                            click={() => onAddNewComment(0)}
                                                            style={{
                                                                height: '28px',
                                                                minWidth: '105px',
                                                                marginTop: '10px'
                                                            }}>
                                                            ADD COMMENT
                                                        </CustomButton>
                                                        <div className="cancel" onClick={() => {
                                                            onMainCommentCancel('', addComment, 'commentField');
                                                            setJoinDiscussion(false);
                                                        }}>
                                                            Cancel
                                                        </div>
                                                    </div> :
                                                    null
                                            }
                                            <div className="rgt">
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                        }
                    </div>
                </div>
                {/* <div className="btn-group">
                </div> */}
            </div>
            {
                (comments && comments.length) ?
                    comments.map((comment, index) => {
                        return (
                            <div id ={`side-panel-parent-comment-${comment.id}`} className="main-comment" key={comment.id}>
                                <div className="side-panel-content">
                                    <div className="user-img"></div>
                                    <div className="user-comment-box">
                                        <div className="user-name">{comment.user}</div>
                                        {
                                            !comment['editComment'] ?
                                                <Fragment>
                                                    <div
                                                        className={`user-comment${!comment.user ? ' no-user' : ''}`}
                                                        title="Edit comment"
                                                        onClick={() => {
                                                            if ((UP_ACCESS(['COMMENTS_UPDATE', 'COMMENTS_UPDATE_SELF', 'PROJECT_COMMENTS_MODIFY_SELF']) && comment['is_owner']) || userRole === 'admin') { editComment(comment); }
                                                        }}
                                                        dangerouslySetInnerHTML={{ __html: comment.message }}>
                                                    </div>
                                                    <div className="info">
                                                        <div className="info-timeline">{comment.created_by_name} commented {comment.history}</div>
                                                        <div className="reply-delete-wrapper">
                                                        {
                                                            // !comment['is_owner'] ?
                                                            ((UP_ACCESS(['REPLIES_CREATE']) || userRole === 'admin')) &&
                                                            <Fragment>
                                                                <div className="info-oval"></div>
                                                                <div className="info-reply" onClick={() => onReplyClick(comment)}>Reply</div>
                                                            </Fragment>
                                                            // null
                                                        }
                                                        {
                                                            !comment['showReplies'] && comment['replies_count'] && UP_ACCESS(['REPLIES_LIST_VIEW']) ?
                                                                <Fragment>
                                                                    <div className="info-oval"></div>
                                                                    <div className="info-reply" onClick={() => {
                                                                        setMainComment(comment);
                                                                        viewReplies(comment, 'show');
                                                                    }}>
                                                                        {comment['replies_count'] === 1 ? 'View Reply' : 'View Replies'}
                                                                    </div>
                                                                </Fragment> :
                                                                comment['replies_count'] && UP_ACCESS(['REPLIES_LIST_VIEW']) ?
                                                                    <Fragment>
                                                                        <div className="info-oval"></div>
                                                                        <div className="info-reply" onClick={() => {
                                                                            setMainComment(comment);
                                                                            viewReplies(comment, 'hide');
                                                                        }}>
                                                                            {comment['replies_count'] === 1 ? 'Hide Reply' : 'Hide Replies'}
                                                                        </div>
                                                                    </Fragment> :
                                                                    null
                                                        }
                                                        {
                                                            (UP_ACCESS(['REPLIES_DELETE', 'REPLIES_DELETE_SELF', 'PROJECT_COMMENTS_DELETE_SELF']) && comment['is_owner']) || userRole === 'admin' ?
                                                                <Fragment>
                                                                    <div className="info-oval"></div>
                                                                    <div className="info-reply" onClick={() => deleteReply(null, comment)}>Delete</div>
                                                                </Fragment> :
                                                                null
                                                        }
                                                        </div>
                                                    </div>
                                                </Fragment> :
                                                <div className={`comment-box${!comment.user ? ' no-user' : ''}`}>
                                                    <div className="txt-area">
                                                        <MentionsInput
                                                            className="mention"
                                                            spellCheck={false}
                                                            value={comment.editCommentField.value}
                                                            allowSpaceInQuery={true}
                                                            allowSuggestionsAboveCursor={true}
                                                            onChange={(event) => inputChangedHandler({ event, comment }, 'editCommentField')}>
                                                            <Mention
                                                                trigger={regex}
                                                                appendSpaceOnAdd={true}
                                                                markup={`<span class="mention-link">@__display__</span>`}
                                                                onAdd={(id, display) => {
                                                                    setMentions([...mentions, display]);
                                                                }}
                                                                style={mentionTextStyle}
                                                                data={analystArray} />
                                                        </MentionsInput>
                                                    </div>
                                                    {
                                                        (UP_ACCESS(['COMMENTS_UPDATE', 'COMMENTS_UPDATE_SELF', 'PROJECT_COMMENTS_MODIFY_SELF']) && comment['is_owner']) || userRole === 'admin' ?
                                                            <div className="btn-group">
                                                                <div className="lft">
                                                                    <CustomButton
                                                                        inverted="inverted"
                                                                        click={() => { // inputChangedHandler({ event: commentHtmlTxt, comment }, 'editCommentField');
                                                                            onCommentUpdate(comment);
                                                                        }}
                                                                        style={{
                                                                            height: '28px',
                                                                            minWidth: '105px',
                                                                            marginTop: '10px'
                                                                        }}>
                                                                        UPDATE
                                                                    </CustomButton>
                                                                    <div className="cancel" onClick={() => editComment(comment, 'undo')}>
                                                                        Cancel
                                                                    </div>
                                                                </div>
                                                            </div> :
                                                            null
                                                    }
                                                </div>
                                        }
                                        {
                                            comment?.textField && comment?.isReplyOn && (UP_ACCESS(['REPLIES_CREATE']) || userRole === 'admin') ?
                                                <div className="comment-box">
                                                    <div className="txt-area">
                                                        <MentionsInput
                                                            className="mention"
                                                            spellCheck={false}
                                                            value={comment.textField.value}
                                                            allowSpaceInQuery={true}
                                                            allowSuggestionsAboveCursor={true}
                                                            onChange={(event) => inputChangedHandler({ event, comment }, 'textField')}>
                                                            <Mention
                                                                trigger={regex}
                                                                appendSpaceOnAdd={true}
                                                                markup={`<span class="mention-link">@__display__</span>`}
                                                                onAdd={(id, display) => {
                                                                    setMentions([...mentions, display]);
                                                                }}
                                                                style={mentionTextStyle}
                                                                data={analystArray} />
                                                        </MentionsInput>
                                                    </div>
                                                    <div className="btn-group">
                                                        <div className="lft">
                                                            <CustomButton
                                                                inverted="inverted"
                                                                click={() => onReply(comment)}
                                                                style={{
                                                                    height: '22px',
                                                                    minWidth: '65px',
                                                                    marginTop: '10px'
                                                                }}>
                                                                POST
                                                            </CustomButton>
                                                            <div className="cancel" onClick={() => onCancel(comment)}>
                                                                Cancel
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                        {
                                            repliesTemplate(comment, 'comment')
                                        }
                                    </div>
                                </div>
                            </div>
                        );
                    }) : null
            }
            {/* Comments from child files */}
            {childrenComments?.length > 0 && <div className="child-comments-wrapper">
                {childrenComments.map((item, index) => {
                    const filePath = Object.keys(item)[0];
                    const comments = item[filePath];
                    return (
                        <div key={index} className="child-comment">
                            <div className="hover-div">{item['display_name']}
                            <span class="hover-text">{filePath}</span>
                            </div>
                            {comments.length > 0 ? (
                                comments.map((comment, idx) => (
                                    <div id={`side-panel-parent-comment-${comment.id}`} className="main-comment" key={comment.id}>
                                        <div className="side-panel-content">
                                            <div className="user-img"></div>
                                            <div className="user-comment-box">
                                                <div className="user-name">{comment.user}</div>
                                                <Fragment>
                                                    <div
                                                        className={`user-comment${!comment.user ? ' no-user' : ''}`}
                                                        style={{cursor: 'pointer'}}
                                                        title="Edit comment"
                                                        dangerouslySetInnerHTML={{ __html: comment.message }}
                                                        onClick={() => setSelectedFile(comment?.file_folder_name)}>
                                                    </div>
                                                    <div className="info">
                                                        <div className="info-timeline">{comment.created_by_name} commented {moment(comment.updated_at).startOf('second').fromNow()}</div>
                                                    </div>
                                                </Fragment>
                                            </div>
                                        </div></div>
                                ))
                            ) : (
                                <p>No comments available.</p>
                            )}
                        </div>
                    );
                })}
            </div>}
        </div>
    )
};

const mapStateToProps = createStructuredSelector({
    possibleValues: getPossibleValues,
    fileCommentsCount: getFilesCommentsCount
});

export default connect(mapStateToProps)(React.memo(CommentsPage));